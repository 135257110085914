<template>
  <div class="container content-themes">
    <div class="page-sub-box clearfix per33 ui-33" style="padding-bottom: 20px">
      <table class="table_form line-bin fl" style="width: 40%">
        <!-- <table class="table_form line-bin fl" style="width: 600px"> -->
        <colgroup>
          <col style="width: 15%" />
          <col style="width: 35%" />
          <col style="width: 15%" />
          <col style="width: 35%" />
        </colgroup>
        <thead class="sub_title_txt">
          <tr>
            <td colspan="4">
              <h2 class="fl">{{ $_msgContents('UC.WORD.DEVICE_INFO') }}</h2>
              <DxButton
                @click="onSelectDevice()"
                :text="$_msgContents('UC.WORD.CHECK')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
              />
            </td>
          </tr>
        </thead>
        <tbody style="height: 374px">
          <!-- <tbody style="height: calc(100vh - 600px)"> -->
          <tr>
            <th scope="row" class="alT">
              <label for="label01">{{ $_msgContents('UC.WORD.EXCHANGER') }}</label>
            </th>
            <th>{{ getCmNm(deviceInfo.cmname) }}</th>
            <td colspan="2" rowspan="3" style="padding: 0px">
              <div class="fl" style="width: 100%; text-align: center">
                <img v-if="deviceImage" :src="deviceImage" alt="Device" />
                <img
                  v-if="deviceExpImage"
                  :src="deviceExpImage"
                  alt="DeviceExp"
                  style="cursor: pointer; position: absolute; bottom: 0px"
                  class="fr"
                  @click="isOpenModal(true, 'expansion')"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label02">{{ $_msgContents('UC.WORD.DEVICE_NAME') }}</label>
            </th>
            <th>{{ deviceInfo.name }}</th>
          </tr>

          <tr>
            <th scope="row" class="alT">
              <label for="label03">{{ $_msgContents('UC.WORD.DEVICEPOOL') }}</label>
            </th>
            <td>
              <DxSelectBox
                :placeholder="$_msgContents('COMPONENTS.SELECT')"
                :items="devicePoolList"
                display-expr="name"
                value-expr="name"
                :styling-mode="stylingMode"
                :width="180"
                :height="30"
                :value="selectedPool"
                @value-changed="onChangeSelectPool($event)"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('UC.WORD.STATUS') }}</label>
            </th>
            <th>{{ deviceInfo.status }}</th>
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('UC.WORD.SERIAL') }}</label>
            </th>
            <th>{{ deviceInfo.serial }}</th>
          </tr>

          <tr>
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('UC.WORD.PHONEBUTTON') }}</label>
            </th>
            <th>{{ deviceInfo.phonetemplatename }}</th>
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('UC.WORD.SOFTKEY') }}</label>
            </th>
            <th>{{ deviceInfo.softkeytemplatename }}</th>
          </tr>
          <tr>
            <th scope="row" class="alT"><label for="label04">IP</label></th>
            <th>{{ deviceInfo.ipaddress }}</th>
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('COMPONENTS.REGISTRATION_DATE') }}</label>
            </th>
            <th>{{ this.formatDt(deviceRegDt) }}</th>
          </tr>
          <tr>
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('UC.WORD.MODEL') }}</label>
            </th>
            <th colspan="3">{{ deviceInfo.model }}</th>
          </tr>
          <tr v-if="expFlag">
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('UC.WORD.EXPANSION_MODULE') }}</label>
            </th>
            <td colspan="3">
              <DxSelectBox
                :placeholder="$_msgContents('COMPONENTS.SELECT')"
                :items="expansionList"
                display-expr="description"
                value-expr="moduleNm"
                :styling-mode="stylingMode"
                :width="400"
                :height="30"
                :value="expansion"
                @value-changed="onChangeExpansion($event)"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="alT">
              <label for="label04">{{ $_msgContents('COMPONENTS.DESCRIPTION') }}</label>
            </th>
            <td colspan="3">
              <DxTextBox
                v-model="deviceInfo.description"
                :placeholder="$_msgContents('UC.MESSAGE.DESCRIPTION_ENTER')"
                :styling-mode="config.stylingMode"
                @value-changed="onChangeInput($event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <table style="width: 1120px; border-top: 0px"> -->
      <table style="width: 60%; border-top: 0px">
        <thead class="sub_title_txt">
          <tr>
            <td colspan="3">
              <h2 style="width: 200px" class="fl">{{ $_msgContents('UC.WORD.LINE_INFO') }}</h2>
              <DxButton
                @click="onUpdateDevice()"
                :text="$_msgContents('UC.WORD.APPLY')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
              />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 0">
              <esp-dx-data-grid
                :data-grid="dataGrid"
                ref="deviceGrid"
                @row-updating="onRowUpdating"
                @cell-prepared="onCellPrepared"
                @editor-prepared="onEditorPrepared"
              />
            </td>
          </tr>
          <DxButton
            @click="goListPage()"
            :text="this.$_msgContents('UC.WORD.PREPAGE')"
            :width="80"
            :height="30"
            class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
            styling-mode="contained"
            style="margin-top: 10px"
            type="default"
          />
        </tbody>
      </table>

      <DxPopup
        :show-title="true"
        :title="modal.expansion.title"
        :width="modal.expansion.width"
        :height="modal.expansion.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.expansion.isOpened"
        @hiding="isOpenModal(false, 'expansion')"
      >
        <DxScrollView width="100%" height="100%">
          <ModalExpansion
            :deviceInfo="this.deviceInfo"
            :cmList="this.cmList"
            :authority="this.authority"
            :isOpened="this.modal.expansion.isOpened"
          ></ModalExpansion>
        </DxScrollView>
      </DxPopup>
      <DxPopup
        :show-title="true"
        :title="modal.pickup.title"
        :width="modal.pickup.width"
        :height="modal.pickup.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.pickup.isOpened"
        @hiding="isOpenModal(false, 'pickup')"
      >
        <ModalPickup
          :deviceInfo="this.deviceInfo"
          :cmList="this.cmList"
          :partitionList="this.partitionList"
          :authority="this.authority"
          :pickupKeyword="this.pickupKeyword"
          :rowInfo="this.rowInfo"
          :isOpened="this.modal.pickup.isOpened"
          @closePickup="setPickup"
        ></ModalPickup>
      </DxPopup>
      <DxPopup
        :show-title="true"
        :title="modal.line.title"
        :width="modal.line.width"
        :height="modal.line.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.line.isOpened"
        @hiding="isOpenModal(false, 'line')"
      >
        <ModalLine
          :deviceInfo="this.deviceInfo"
          :cmList="this.cmList"
          :partitionList="this.partitionList"
          :cssList="this.cssList"
          :pickupGroupList="this.pickupGroupList"
          :rowInfo="this.rowInfo"
          :isOpened="this.modal.line.isOpened"
          @closeLine="setLine"
        ></ModalLine>
      </DxPopup>
      <DxPopup
        :show-title="true"
        :title="modal.sd.title"
        :width="modal.sd.width"
        :height="modal.sd.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.sd.isOpened"
        @hiding="isOpenModal(false, 'sd')"
      >
        <ModalSd
          :deviceInfo="this.deviceInfo"
          :cmList="this.cmList"
          :sdData="this.modal.sd.data"
          :isOpened="this.modal.sd.isOpened"
          :authority="this.authority"
          @closeSd="setLine"
        ></ModalSd>
      </DxPopup>
    </div>
  </div>
</template>

<script>
  import DxButton from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import ModalExpansion from './modal-set-expansion.vue';
  import ModalPickup from './modal-set-pickup.vue';
  import ModalLine from './modal-set-line.vue';
  import ModalSd from './modal-set-sd.vue';
  import { DxPopup } from 'devextreme-vue/popup';
  import PlusIcon from '@/assets/images/write.png';
  import DelIcon from '@/assets/images/del.png';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxTextBox,
      DxSelectBox,
      DxPopup,
      ModalExpansion,
      ModalPickup,
      ModalLine,
      DxScrollView,
      ModalSd,
    },
    props: {},
    watch: {},
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        devicePoolList: [],
        selectedPool: '',
        deviceRegDt: '',
        cssList: [],
        partitionList: [],
        cmList: [],
        pickupGroupList: [],
        recList: [],
        expansion: '',
        expansionList: [],
        modelList: [],
        expFlag: false,
        pickupKeyword: '',
        deviceImage: '',
        deviceExpImage: '',
        deviceData: {},
        authority: 'manager',
        modal: {
          expansion: {
            isOpened: false,
            // title: '확장모듈 설정',
            title: this.$_msgContents('UC.WORD.EXPANSION_MODULE_SET'),
            width: 'calc(100vw - 10%)',
            height: '900',
          },
          pickup: {
            isOpened: false,
            // title: '당겨받기 설정',
            title: this.$_msgContents('UC.WORD.PICKUPGROUP_SET'),
            width: '70%',
            height: '700',
          },
          line: {
            isOpened: false,
            // title: '전화번호 설정',
            title: this.$_msgContents('UC.WORD.LINE_SET'),
            width: '60%',
            height: '700',
          },
          sd: {
            isOpened: false,
            data: [],
            title: '단축다이얼 설정',
            width: '30%',
            height: '300',
          },
        },
        updateFlag: false,
        deviceInfo: {},
        devicelist: [],
        rowInfo: {},
        rowIndex: '',
        changedDeviceInfo: [],
        dataGrid: {
          refName: 'deviceGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          dataSource: [],
          // width: '1120', // 주석처리시 100%
          height: '376', // 주석처리시 100%
          apiActionNm: {
            update: '',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true,
            saving: true,
            rowUpdating: true,
            rowUpdated: true,
            editorPrepared: true,
          },
          showActionButtons: {
            excel: false, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '유형',
              i18n: 'UC.WORD.TYPE',
              dataField: 'field',
              width: 80,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                const imgData = this.$_getAttachFileURL(null, options.value);
                imgTag.setAttribute('src', imgData);
                container.append(imgTag);
              },
            },
            {
              caption: '전화번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'number',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: async (container, options) => {
                const divTag = document.createElement('div');
                if (options.data.field === 'SURL') {
                  divTag.innerText = '-';
                } else {
                  let field = 'line';
                  if (options.data.field == 'LINE') {
                    field = 'line';
                  } else if (options.data.field == 'SD' || options.data.field == 'BLF') {
                    field = 'sd';
                  }
                  if (options.value == null || options.value == 'null') {
                    options.value = '';
                  }
                  const value = options.value ? options.value + ' ' : options.value;
                  container.append(value);

                  divTag.classList.add('fr');
                  const imgTag = document.createElement('img');
                  imgTag.setAttribute('src', PlusIcon);
                  imgTag.style.cursor = 'pointer';
                  imgTag.addEventListener('click', async () => {
                    await this.setRowInfo(options);
                    await this.isOpenModal(true, field, options.data);
                  });

                  divTag.append(imgTag);
                  if (value != '') {
                    const del = document.createElement('img');
                    del.setAttribute('src', DelIcon);
                    del.style.cursor = 'pointer';
                    del.addEventListener('click', async () => {
                      await this.setCellDelete(options, 'number');
                    });
                    divTag.append(del);
                  }
                }
                container.append(divTag);
              },
            },

            {
              caption: '레이블',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowSorting: false,
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateDisplayCellValue: rowData => {
                if (rowData?.label) {
                  return rowData.label;
                }
                if (rowData.label == null || rowData.label == 'null') {
                  return '';
                }
              },
              cellTemplate: (container, options) => {
                const span = document.createElement('span');
                if (options.data.field === 'SURL') {
                  span.innerText = '-';
                } else {
                  span.innerText = options.value;
                }
                container.append(span);
              },
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              // width: 140,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.routepartitionname) {
                  return rowData.routepartitionname;
                }
                if (rowData.routepartitionname == null || rowData.routepartitionname == 'null') {
                  return 'NONE';
                }
                if (rowData.field !== 'LINE') {
                  return '-';
                }
              },
            },
            {
              caption: '발신검색',
              i18n: 'UC.WORD.CSS',
              dataField: 'callingsearchspacename',
              // width: 140,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowSorting: false,
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateDisplayCellValue: rowData => {
                if (rowData?.callingsearchspacename) {
                  if (rowData.callingsearchspacename.toUpperCase() === 'NONE') {
                    return rowData.callingsearchspacename.toUpperCase();
                  }
                  return rowData.callingsearchspacename;
                }
                if (rowData.callingsearchspacename == null || rowData.callingsearchspacename == 'null') {
                  return 'NONE';
                }
                if (rowData.field !== 'LINE') {
                  return '-';
                }
              },
            },

            {
              caption: '착신전환',
              i18n: 'UC.WORD.FORWARDNO',
              dataField: 'forwardno',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              allowUpdating: true,
              sortOrder: 'none', // acs desc none
              allowSorting: false,
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              // calculateDisplayCellValue: rowData => {
              //   if (rowData?.forwardno) {
              //     return rowData.forwardno;
              //   }
              //   if (rowData.forwardno == null || rowData.forwardno == 'null') {
              //     rowData.forwardno = '';
              //     return rowData.forwardno;
              //   }
              //   if (rowData.field !== 'LINE') {
              //     return '-';
              //   }
              // },
              cellTemplate: (container, options) => {
                if (options.value === null || typeof options.value === 'undefined') {
                  options.value = '';
                }
                if (options.data.field === 'LINE') {
                  container.append(options.value);
                } else {
                  const span = document.createElement('span');
                  span.innerText = '-';
                  container.append(span);
                }
              },
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: function (e) {
                    if (e.value === '') {
                      return true;
                    }
                    return !isNaN(e.value) && e.value !== null && e.value !== '';
                  },
                  message: this.$_msgContents('UC.MESSAGE.NUMBER_ENTER'),
                },
              ],
            },
            {
              caption: '당겨받기',
              i18n: 'UC.WORD.PICKUPGROUP',
              dataField: 'pickupgroupname',
              width: 180,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                if (options.value === null || typeof options.value === 'undefined') {
                  options.value = '';
                }
                if (options.data.field === 'LINE') {
                  let description = '';
                  for (let i = 0; i < this.pickupGroupList.length; i++) {
                    if (this.pickupGroupList[i].name === options.value) {
                      description = this.pickupGroupList[i].description;
                    }
                  }
                  const value = description;
                  container.append(value + ' ');
                  const divTag = document.createElement('div');
                  divTag.classList.add('fr');
                  const imgTag = document.createElement('img');
                  imgTag.setAttribute('src', PlusIcon);
                  imgTag.style.cursor = 'pointer';
                  imgTag.addEventListener('click', async () => {
                    await this.setRowInfo(options);
                    await this.isOpenModal(true, 'pickup', options.data);
                  });

                  divTag.append(imgTag);
                  if (value != '') {
                    const del = document.createElement('img');
                    del.setAttribute('src', DelIcon);
                    del.style.cursor = 'pointer';
                    del.addEventListener('click', async () => {
                      await this.setCellDelete(options, 'pickup');
                    });
                    divTag.append(del);
                  }
                  container.append(divTag);
                } else {
                  const span = document.createElement('span');
                  span.innerText = '-';
                  container.append(span);
                }
              },
            },
            {
              caption: '녹취',
              i18n: 'UC.WORD.REC',
              dataField: 'recprofile',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              fixedPosition: 'left',
              calculateDisplayCellValue: rowData => {
                if (rowData.recprofile == null || rowData.forwardno == 'null') {
                  rowData.forwardno = '';
                  return rowData.forwardno;
                }
                if (rowData?.recprofile) {
                  return rowData.recprofile;
                }
              },
              cellTemplate: (container, options) => {
                if (options.value == 'NONE') {
                  options.value = 'None';
                }
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_msgContents('COMPONENTS.SELECT'),
                    items: this.recList,
                    displayExpr: 'recname',
                    valueExpr: 'recprofile',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.deviceGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                      this.$refs.deviceGrid.getGridInstance.saveEditData();
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**@description 교환기 장치정보 조회하는 메서드 */
      async selectDataList() {
        if (!this.reqParams) {
          this.$_goPrePage();
          return;
        }

        const data = this.reqParams.deviceData;
        this.deviceRegDt = data.regDt;
        let params = { mac: data.name, cmNm: data.cmNm, loginId: this.$store.getters.getLoginId, type: 'MAC' };
        const payload = {
          actionname: 'EUC_DEVICE_CISCO_DETAIL',
          data: params,
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          const deviceInfo = res.data.data[0];
          if (deviceInfo.LIST?.length > 0) {
            this.deviceInfo = deviceInfo.LIST[0];
            this.devicelist = deviceInfo.LIST[0].devicelist;
            await this.findModelByExpansion();
          } else {
            await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_DATA'));
            await this.$_goPrePage();
            return;
          }

          if (this.deviceInfo?.model) {
            this.deviceImage = this.$_getAttachFileURL(null, this.deviceInfo.model);
          } else {
            this.deviceImage = this.deviceInfo.model;
          }
          if (this.deviceInfo?.addonmodules) {
            this.deviceExpImage = this.$_getAttachFileURL(null, this.deviceInfo.addonmodules);
          } else {
            this.deviceExpImage = this.deviceInfo.addonmodules;
          }
          this.selectedPool = this.deviceInfo.devicepool;
          this.expansion = this.deviceInfo.addonmodules;
          this.dataGrid.dataSource = this.devicelist;
        } else {
          await this.$_Msg(res.data.header.resMsg);
          await this.$_goPrePage();
        }
        await this.$refs.deviceGrid.getGridInstance.clearSelection();
      },
      /**@description : DevicePool selectBox 변경 이벤트 */
      onChangeSelectPool(event) {
        this.selectedPool = event.value;
        this.updateFlag = true;
      },
      /**@description : Expansion selectBox 변경 이벤트 */
      onChangeExpansion(event) {
        this.expansion = event.value;
        this.updateFlag = true;
      },
      /**@description 조회버튼 클릭 이벤트  */
      async onSelectDevice() {
        this.changedDeviceInfo = []; //재조회시 그리드 수정내역 변경
        const params = {
          mac: this.deviceInfo.name,
          cmNm: this.deviceInfo.cmname,
          loginId: this.$store.getters.getLoginId,
          type: 'MAC',
        };
        const payload = {
          actionname: 'EUC_DEVICE_CISCO_DETAIL',
          data: params,
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            const deviceInfo = res.data.data[0];
            await this.setDeviceData(deviceInfo);
          }
        } else {
          await this.$_Msg(res.data.header.resMsg);
          await this.$_goPrePage();
        }
        await this.$refs.deviceGrid.getGridInstance.clearSelection();
      },
      /** @description: 단말장치 데이터 상세 조회 메서드 */
      async setDeviceData(result) {
        if (!result) {
          this.$_goPrePage();
          return;
        }
        const refDeviceData = result.LIST[0];
        const refGridData = refDeviceData.devicelist;

        if (refDeviceData?.model) {
          this.deviceImage = this.$_getAttachFileURL(null, refDeviceData.model);
        } else {
          this.deviceImage = '';
        }
        if (refDeviceData?.addonmodules) {
          this.deviceExpImage = this.$_getAttachFileURL(null, refDeviceData.addonmodules);
        } else {
          this.deviceExpImage = '';
        }
        if (refGridData.length > 0) {
          this.devicelist = refGridData;
        }
        this.deviceInfo = refDeviceData;
        this.dataGrid.dataSource = this.devicelist;
        this.selectedPool = refDeviceData.devicepool;
        this.expansion = this.deviceInfo.addonmodules;
      },
      /**@description 그리드 customEvent @onRowUpdating*/
      onRowUpdating(e) {
        let column = Object.keys(e.newData)[0];
        let value = e.newData[column];
        let number = e.oldData.number;
        let field = e.oldData.field;
        let newRow = { column: column, value: value, number: number, field: field };
        const existingRow = this.changedDeviceInfo.find(row => row.column === column && row.number === number);
        if (existingRow) {
          Object.assign(existingRow, newRow);
        } else {
          this.changedDeviceInfo.push(newRow);
        }
      },
      /**@description  단말기 정보 업데이트
       * @param
       * */
      async onUpdateDevice() {
        if (this.$refs.deviceGrid.hasEditData()) {
          await this.$_Msg('수정중인 항목이있습니다. ');
          return false;
        }
        const changeArr = [];

        for (let i = 0; i < this.changedDeviceInfo.length; i++) {
          let row = {
            TYPE: '',
            DEVICE: '',
            ROUTEPARTITION: '',
          };
          const routeRow = this.dataGrid.dataSource.find(row => row.number === this.changedDeviceInfo[i].number);

          row.DEVICE = this.changedDeviceInfo[i].number;
          if (routeRow.field === 'LINE') {
            row.ROUTEPARTITION = routeRow.routepartitionname;
          } else {
            row.ROUTEPARTITION = '';
          }

          switch (this.changedDeviceInfo[i].column) {
            case 'label':
              row.TYPE = this.changedDeviceInfo[i].field + 'LABEL';
              row.LABEL = this.changedDeviceInfo[i].value;
              break;
            case 'forwardno':
              row.TYPE = 'FORWARDCALL';
              row.FORWARD_NO = this.changedDeviceInfo[i].value;
              break;
            case 'pickupgroupname':
              row.TYPE = 'PICKUP';
              row.PICKUPGROUP = this.changedDeviceInfo[i].value;
              break;
            case 'recprofile':
              row.TYPE = 'REC';
              row.RECPROFILE = this.changedDeviceInfo[i].value;
              row.RECFLAG = this.getRecInfo(this.changedDeviceInfo[i].value, 'recflag');
              row.RECMEDIASOURCE = this.getRecInfo(this.changedDeviceInfo[i].value, 'recmediasource');
              break;
            case 'callingsearchspacename': // 발신검색 항목 추가 필요
              break;
          }
          changeArr.push(row);
        }
        if (changeArr.length === 0 && this.updateFlag == false) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_CHANGED'));
          return;
        } else if (changeArr.length > 0 || this.updateFlag == true) {
          const confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });

          if (!(await this.$_Confirm(confirm))) {
            return;
          }

          const params = {
            cmNm: this.deviceInfo.cmname,
            mac: this.deviceInfo.name,
            devicepool: this.selectedPool,
            description: this.deviceInfo.description,
            expansionModule: this.expansion,
            list: JSON.stringify(changeArr),
            loginId: this.$store.getters.getLoginId,
          };
          const payload = {
            actionname: 'EUC_DEVICE_CISCO_UPDATE',
            data: params,
            loading: true,
          };
          const res = await this.CALL_EUC_API(payload);

          if (isSuccess(res)) {
            await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            this.changedDeviceInfo = [];
            await this.onSelectDevice();
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
      },
      /**@description IPT CODE 가져오는 메서드 */
      async selectCodeList(sort = 'codeKey,codeOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CODE_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.recList = this.setRecList(res);
        }
      },
      /**@description		코드에서 녹취 항목만 저장하는 함수 코드순서로 일치 항목 확인
       * @param 	{Array}	코드리스트
       * @returns {Array} 녹취리스트
       */
      setRecList(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          const codeList = res.data.data;
          const recList = [];

          for (let i = 0; i < codeList.length; i++) {
            let list = {
              recprofile: '',
              recflag: '',
              recname: '',
              recmediasource: '',
              ord: '',
            };
            if (codeList[i].codeKey === 'recprofile' && codeList[i].parentId != null) {
              list.recprofile = codeList[i].codeValue;
              list.recname = codeList[i].codeNm;
              list.ord = codeList[i].codeOrd;
              recList.push(list);
            }
          }

          for (let i = 0; i < codeList.length; i++) {
            for (let j = 0; j < recList.length; j++) {
              if (codeList[i].codeKey === 'recflag' && codeList[i].parentId != null) {
                if (recList[j].ord === codeList[i].codeOrd) {
                  recList[j].recflag = codeList[i].codeValue;
                }
              }
              if (codeList[i].codeKey === 'recmediasource' && codeList[i].parentId != null) {
                if (recList[j].ord === codeList[i].codeOrd) {
                  recList[j].recmediasource = codeList[i].codeValue;
                }
              }
            }
          }
          return recList;
        }
      },
      /**@description : 설명 입력창 onChange이벤트*/
      onChangeInput(event) {
        this.updateFlag = true;
        this.deviceInfo.description = event.value;
      },
      /**@description : 팝업 Open/Close */
      isOpenModal(data, type, row) {
        if (row && type == 'pickup') {
          //키워드 설정 방식 변경필요 , 부서 정보 가져올때
          let keyword = '';
          for (let i = 0; i < this.pickupGroupList.length; i++) {
            if (this.pickupGroupList[i].name === row.pickupgroupname) {
              keyword = this.pickupGroupList[i].description;
            }
          }
          this.pickupKeyword = keyword;
        } else if (row && type == 'sd') {
          this.modal[type].data = JSON.parse(JSON.stringify(this.dataGrid.dataSource.filter(d => d.field === 'SD' || d.field === 'BLF')));
        }
        this.modal[type].isOpened = data;
      },
      /**@description : 당겨받기 데이터 저장하는 메서드 */
      setRowInfo(rowData) {
        this.rowInfo = Object.assign({}, rowData.data);
        this.rowIndex = rowData.rowIndex;
      },
      /**@description : 교환기 목록 가져오는 메서드 */
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.setCmName(res);
        }
      },
      /**@description : 교환기 목록에서 불필요 부분 제거하는 메서드 */
      async setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewFl;
          }
        }
      },
      /** @description 교환기 이름 한글 변환 메서드
       *  @param {String} cm Name
       *  @returns {String} 한글명
       */
      getCmNm(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
      /**@description devicepool 목록 가져오는 메서드 */
      async selectDevicePool(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_DEVICEPOOL_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.devicePoolList = res.data.data;
          }
        }
      },
      /**@description 파티션 목록 가져오는 메서드 */
      async selectPartitionList(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_PARTITION_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.partitionList = res.data.data;
          }
        }
      },
      /**@description 발신검색 목록 가져오는 메서드 */
      async selectCss(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CSS_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.cssList = res.data.data;
          }
        }
      },
      /**@description 당겨받기그룹 목록 가져오는 메서드 */
      async selectPickupGroupList(sort = 'regDt,id') {
        let params = { sort: sort, viewFl: 'Y' };

        const payload = {
          actionname: 'EUC_PICKUPGROUP_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.pickupGroupList = res.data.data;
          }
        }
      },
      /**@description 녹취 프로파일 값을 받아 해당하는 다른 녹취키 값 리턴하는 메서드
       * @param {String} 녹취 프로파일 데이터
       * @param {String} 검색할 녹취 키 값
       * @returns {String} 해당하는 녹취 키에 대한 value
       */
      getRecInfo(data, type) {
        const recArr = this.recList;
        let returnData = '';
        for (let i = 0; i < recArr.length; i++) {
          if (data === recArr[i].recprofile) {
            returnData = recArr[i][type];
          }
        }
        return returnData;
      },

      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },
      /**@description 당겨받기설정 모달 종료 후 콜백 메서드 , 넘겨받은 데이터 grid에 반영
       * @param {Object} 설정하려는 row데이터
       * @param {String} 당겨받기 그룹 이름
       */
      async setPickup(row, pickupName) {
        this.isOpenModal(false, 'pickup');
        const gridData = this.dataGrid.dataSource;
        for (let i = 0; i < gridData.length; i++) {
          if (gridData[i].index === row.index && gridData[i].seq === row.seq && gridData[i].field === row.field) {
            this.$refs.deviceGrid.getGridInstance.cellValue(i, 6, pickupName);
            this.$refs.deviceGrid.getGridInstance.saveEditData();
          }
        }
      },
      /**@description 내선설정 모달 종료 후 콜백 메서드 , 넘겨받은 데이터 grid에 반영
       * @param {Object} 설정하려는 row데이터
       * @param {Object} 모달창에서 선택한 라인데이터
       */
      async setLine(row, lineData, type) {
        this.isOpenModal(false, type);
        const gridData = this.dataGrid.dataSource;
        if (type == 'line') {
          for (let i = 0; i < gridData.length; i++) {
            if (gridData[i].index == row.index && gridData[i].seq == row.seq && gridData[i].field == row.field) {
              gridData[i].number = lineData.pattern;
              gridData[i].label = lineData.description;
              gridData[i].routepartitionname = lineData.routepartitionname;
              gridData[i].css = lineData.css;
            }
          }
        } else {
          for (let i = 0; i < gridData.length; i++) {
            for (let j = 0; j < lineData.length; j++) {
              if (
                gridData[i].index === lineData[j].index &&
                gridData[i].seq === lineData[j].seq &&
                gridData[i].field === lineData[j].field
              ) {
                this.$refs.deviceGrid.getGridInstance.cellValue(i, 1, lineData[j].number);
                this.$refs.deviceGrid.getGridInstance.cellValue(i, 2, lineData[j].label);
              }
            }
          }
          this.$refs.deviceGrid.getGridInstance.saveEditData();
        }
        await this.onCreateDevice();
      },
      /**@description 할당된 내선 삭제하는 메서드 */
      async onDeleteDevice() {
        let dataSource = this.dataGrid.dataSource;
        const mergeArray = [];
        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].number != null && dataSource[i].number != '') {
            let data = {};
            data.INDEX = dataSource[i].seq;
            data.FIELD = dataSource[i].field;
            data.PATTERN = dataSource[i].number == null ? '' : dataSource[i].number;
            data.LABEL = dataSource[i].label == null ? '' : dataSource[i].label;
            if (dataSource[i].field == 'LINE') {
              data.ROUTEPARTITION = dataSource[i].routepartitionname == null ? 'None' : dataSource[i].routepartitionname;
              data.CSS = dataSource[i].callingsearchspacename == null ? 'None' : dataSource[i].callingsearchspacename;
              data.RECPROFILE = dataSource[i].recprofile == '' ? 'None' : dataSource[i].recprofile;
              data.RECFLAG = this.getRecInfo(dataSource[i].recprofile == '' ? 'None' : dataSource[i].recprofile, 'recflag');
              data.RECMEDIASOURCE = this.getRecInfo(dataSource[i].recprofile == '' ? 'None' : dataSource[i].recprofile, 'recmediasource');
            }
            mergeArray.push(data);
          }
        }

        let params = {
          mac: this.deviceInfo.name,
          cmNm: this.deviceInfo.cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        };
        const payload = {
          actionname: 'EUC_DEVICE_CISCO_MERGE',
          data: params,
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
        await this.onSelectDevice();
      },
      /**@description : 내선 설정 함수 */
      async onCreateDevice() {
        const dataSource = this.dataGrid.dataSource;
        const mergeArray = [];
        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].number != null && dataSource[i].number != '') {
            let data = {};
            data.INDEX = dataSource[i].seq;
            data.FIELD = dataSource[i].field;
            data.PATTERN = dataSource[i].number == null ? '' : dataSource[i].number;
            data.LABEL = dataSource[i].label == null ? '' : dataSource[i].label;
            if (dataSource[i].field == 'LINE') {
              data.ROUTEPARTITION = dataSource[i].routepartitionname == null ? 'None' : dataSource[i].routepartitionname;
              data.CSS = dataSource[i].callingsearchspacename == null ? 'None' : dataSource[i].callingsearchspacename;
              data.RECPROFILE = dataSource[i].recprofile == '' ? 'None' : dataSource[i].recprofile;
              data.RECFLAG = this.getRecInfo(dataSource[i].recprofile == '' ? 'None' : dataSource[i].recprofile, 'recflag');
              data.RECMEDIASOURCE = this.getRecInfo(dataSource[i].recprofile == '' ? 'None' : dataSource[i].recprofile, 'recmediasource');
            }
            mergeArray.push(data);
          }
        }

        let params = {
          mac: this.deviceInfo.name,
          cmNm: this.deviceInfo.cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        };

        const payload = {
          actionname: 'EUC_DEVICE_CISCO_MERGE',
          data: params,
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
        await this.onSelectDevice();
      },
      /** @description : 그리드 셀 관련 준비 커스텀 이벤트 */
      onCellPrepared(e) {
        if (e.rowType === 'data') {
          if (e.columnIndex === 0 || e.columnIndex === 3 || e.columnIndex === 4 || e.data.field === 'SURL') {
            e.cellElement.classList.add('grid-edit-false');
          }

          if (e.data.field !== 'LINE') {
            if (e.data.field === 'SURL') {
              e.cellElement.classList.add('grid-edit-false');
            } else if (e.data.field === 'SD' || e.data.field === 'BLF') {
              if (e.columnIndex > 3) {
                e.cellElement.classList.add('grid-edit-false');
              }
            }
          }
        }
      },

      /** @description 그리드 셀 편집기가 생성된 후 실행되는 함수 */
      onEditorPrepared(e) {
        if (e.row.cells[0].value === 'SURL') {
          e.component.cancelEditData();
        }

        if (e.row.cells[0].value === 'SD' || e.row.cells[0].value === 'BLF') {
          if (e.index > 3) {
            e.component.cancelEditData();
          }
        }
        if (e.row.cells[1].value === null || e.row.cells[1].value === '') {
          e.component.cancelEditData();
        }
      },
      /** @description 이전페이지 이동 함수 */
      goListPage() {
        if (this.$store.getters?.getPreviousPath) {
          this.$_goPrePage();
        } else {
          this.$router.push('/euc/device');
        }
      },
      /**@description 내선, 당겨받기 항목의 X버튼 클릭 이벤트.
       * @param {Object} 삭제하려는 row 데이터
       * @param {String} 삭제하려는 컬럼 타입
       */
      setCellDelete(row, type) {
        this.$refs.deviceGrid.getGridInstance.cellValue(row.rowIndex, row.columnIndex, '');
        this.$refs.deviceGrid.getGridInstance.saveEditData();
        if (type == 'number') {
          this.onDeleteDevice();
        }
      },
      /**
       * @description 확장모듈리스트
       */
      async selectExpansionList(sort = '+moduleOrd, +id') {
        let params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_EXPANSION_LIST_ALL',
          data: params,
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data?.length > 0) {
            this.expansionList = res.data.data;
          }
        }
      },

      /**
       * @description 모델리스트
       */
      async selectModelList(sort = '+modelOrd , +id') {
        let params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_MODEL_LIST_ALL',
          data: params,
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data?.length > 0) {
            this.modelList = res.data.data;
          }
        }
      },
      /** @description model별 확장모듈 리스트 변경 */
      async findModelByExpansion() {
        let model = this.deviceInfo.model;
        if (model != null && model != '') {
          let data = this.modelList.filter(d => d.name == model);
          if (data.length > 0 && data[0]?.expansionModId) {
            let ids = data[0].expansionModId.split(',');
            let exp = ids.map(item => {
              return this.expansionList.filter(d => d.id == item)[0];
            });
            exp.unshift({ moduleNm: 'None', description: '미사용' });
            this.expansionList = exp;
            this.expFlag = true;
          } else {
            this.expFlag = false;
          }
        }
      },

      /**@description : 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        this.$_setPageSettingConfig();
        this.reqParams = this.$store.getters.getDetailParams;
        await this.selectExpansionList();
        await this.selectModelList();
        await this.selectDataList();
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        await this.selectCmList();
        await this.selectDevicePool();
        await this.selectPartitionList();
        await this.selectCss();
        await this.selectPickupGroupList();
        await this.selectCodeList();
      },
      /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {
        this.$store.commit('setDetailParams', null);
      },
    },
    async created() {
      await this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>

<style scoped>
  .device-img > img {
    align-self: center;
  }

  .fl {
    border-right: 0 !important;
  }

  tbody {
    display: table-row-group;
    vertical-align: top;
  }

  .table_form tbody td {
    border: 1px solid #ddd;
    padding: 8px 18px;
  }
  .table_form tbody th {
    border: 1px solid #ddd;
    padding: 8px 18px;
  }
</style>
<style>
  .grid-edit-false {
    background-color: #f9f9f9;
  }
</style>
